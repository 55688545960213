import pinUrl from "/img/pin.svg";
import phoneUrl from "/img/phone.svg";
import mailUrl from "/img/mail.svg";

const googleMapUrl = "https://www.google.com/maps/place/aleja+Wojska+Polskiego+50,+80-271+Gdańsk,+Poland/@54.386467,18.584715,15z/data=!4m5!3m4!1s0x46fd74de7d2af7d5:0x84021ab77d5a9290!8m2!3d54.388116!4d18.5814531?hl=en-GB";
const email = "kontakt@psychoterapiaprzyzajezdni.pl";
const content = {
    method: `Terapia psychodynamiczna to specjalistyczna metoda leczenia, opierająca się na założeniu, że do zrozumienia życia psychicznego wymagana jest refleksja nad nieświadomością. Podstawowym narzędziem pracy w terapii jest rozmowa.
    <br><br>Dzięki opartej na zaufaniu relacji terapeutycznej przepracujesz wzorce własnego funkcjonowania. Ich zmiana prowadzi do obniżenia symptomów i poprawy jakości życia.<br><br>
Cele terapii uzależnione są od Twoich potrzeb. Mogą nimi być np. zmniejszenie impulsywnych zachowań, zdolność do budowania satysfakcjonującego związku, zrozumienie siebie, leczenie różnych zaburzeń oraz chorób psychicznych.
`,
    about: `Jestem psycholożką i psychoterapeutką w trakcie certyfikacji.<br>Ukończyłam 4-letnią <span class="font-medium">Szkołę Psychoterapii Psychodynamicznej</span> oraz 2-letnie <span class="font-medium">Studium Socjoterapii i Psychoterapii Młodzieży</span> w <span class="font-medium">Krakowskim Centrum Psychodynamicznym</span>.<br>Jestem absolwentką studiów magisterskich <span class="font-medium">Wydziału Nauk Społecznych Uniwersytetu Gdańskiego</span>.
<br><br>Doświadczenie zawodowe zdobywałam w trójmiejskich fundacjach i ośrodkach oferujących wsparcie psychologiczne oraz terapię młodzieży i dorosłych. Pracowałam również w Nadmorskim Centrum Medycznym w Oddziale dziennym leczenia zaburzeń nerwicowych i depresyjnych.<br><br>Należę do <span class="font-medium">Polskiego Towarzystwa Psychoterapii Psychodynamicznej</span>.<br>Swoją pracę poddaję
    regularnej superwizji.`,
    offer: `Prowadzę indywidualną terapię psychodynamiczną osób dorosłych.
Psychoterapia poprzedzona jest kilkoma konsultacjami, by zweryfikować problematykę zgłoszenia do gabinetu, określić cel terapeutyczny i omówić warunki leczenia. Po wspólnej decyzji o rozpoczęciu terapii sesje odbywają się 2 razy w tygodniu. 
    <br><br><span class='text-strong'>Sesja psychoterapii indywidualnej / konsultacja:</span><br>170 zł / 50 minut<br><br>
Zapraszam na konsultacje po wcześniejszym umówieniu telefonicznym, poprzez <span class="font-medium">sms</span> lub <a class="color-feature" href="mailto:${email}">e-mail</a>. Jeśli nie mogę odebrać telefonu <span class='nowrap'>- oddzwaniam.</span> Gabinet znajduje się w dzielnicy <span class="font-medium"> Gdańsk - Strzyża</span> na wysokości przystanku tramwajowego Zajezdnia. Dostępne są darmowe miejsca parkingowe przed budynkiem<br><br>`,


    contact_header_mobile: `<span class=\"contact-name\">Marta Krenczkowska</span>`,
    contact_header: `<div class='contact-detail'><div class='contact-detail-icon'></div>Psychoterapia przy Zajezdni</div>
                    <div class='contact-detail'><div class='contact-detail-icon'></div><span class="text-strong">Marta Krenczkowska</span></div>`,

    contact: `<div class='contact-group'><div class='contact-detail'><div class='contact-detail-icon'><img src=${pinUrl}></div>Aleja Wojska Polskiego 50/6</div>
    <div class='contact-detail'><div class='contact-detail-icon'></div>80-268 Gdańsk</div></div>
    <div class='contact-group'><div class='contact-detail'><div class='contact-detail-icon'><img src=${phoneUrl}></div><a href=\"tel:+48602 894 521\">+48 602 894 521</a></div></div>
    <div class='contact-group'><div class='contact-detail'><div class='contact-detail-icon contact-detail-icon-mail'><img src=${mailUrl}></div><a href="mailto:${email}" class='contact-mail'>${email}</a></div></div>
  </div>`,
}

document.getElementById("psychoterapia-text").innerHTML = content.method;
document.getElementById("o_mnie_text").innerHTML = content.about;
document.getElementById("oferta-text").innerHTML = content.offer;
document.getElementsByClassName("contact-container")[0].innerHTML = content.contact_header + content.contact;
document.getElementById("contact-container-mobile").innerHTML = content.contact_header_mobile + content.contact;


